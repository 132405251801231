<template>
  <b-row>
    <b-col md="3" class="mt-4">
      <b-card>
        <ul class="list-unstyled">
          <li
            v-bind:class="{ 'btn-flat-primary': selectedReport === x.title }"
            @click="
              () => {
                selectedReport = x.title;
                selected = x;
              }
            "
            class="p-1 nav-item-report cursor-pointer border-bottom"
            v-for="x in reports"
            :key="x.title"
            v-permission="x.permission"
          >
            <feather-icon :icon="x.icon" size="20" class="mr-75" />
            <span>{{ $t(x.title) }}</span>
          </li>
        </ul>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card-title
        v-if="selectedReport"
        class="p-0 m-0 text-primary text-darken-5"
      >
        <b-row class="p-0">
          <b-col md="12" class="text-center">
            <span>{{ $t(selectedReport) }}</span>
          </b-col>
        </b-row>
      </b-card-title>
      <hr v-if="selectedReport" class="border-info border-darken-2" />
      <collectionVouchers
        :type="selected"
        v-if="
          selectedReport === 'collectionVouchers' ||
          selectedReport === 'paymentVouchers' ||
          selectedReport === 'studentsAccountStatement'
        "
      />
      <incomeTransaction
        :type="selected"
        v-if="
          selectedReport === 'incomeTransaction' ||
          selectedReport === 'expenseTransaction'
        "
      />
      <financialTransactionAllocation
        :type="selected"
        v-if="
          selectedReport === 'financialTransactionAllocation' ||
          selectedReport === 'financialTransactionAllocation'
        "
      />
      <salesAllocation
        :type="selected"
        v-if="
          selectedReport === 'reportSalesAllocation'
        "
      />
      <postedAndUnpostedInvoicesToZatca
        :type="selected"
        v-if="
          selectedReport === 'postedAndUnPostedInvoicesToZatcaReport'
        "
      />
    </b-col>
  </b-row>
</template>
  <script>
import { transactionReports } from '@/libs/acl/Lookups';
import collectionVouchers from '@/pages/Settings/Report/collectionVouchers.vue';
import incomeTransaction from '@/pages/Settings/Report/incomeTransaction.vue';
import financialTransactionAllocation from '@/pages/Settings/Report/financialTransactionAllocation.vue';
import salesAllocation from '@/pages/Settings/Report/salesAllocation.vue';
import postedAndUnpostedInvoicesToZatca from '@/pages/Settings/Report/postedAndUnpostedInvoicesToZatca.vue';
import reportMixin from '@/mixin/reportMixin';

export default {
  components: {
    collectionVouchers,
    incomeTransaction,
    financialTransactionAllocation,
    salesAllocation,
    postedAndUnpostedInvoicesToZatca
  },
  mixins: [
    reportMixin,
  ],
  data() {
    return {
      menu: transactionReports,
      reports: [],
      selectedReport: '',
      selected: {}
    };
  },
  computed: {},
  mounted() {
    this.reports = this.menu;
    this.selectedReport = 'collectionVouchers'
    if (this.profile.permissions.length > 0) {
      this.selectedReport = this.getSelectedReport(this.reports, this.profile.permissions);
    }
    this.selected = this.reports.find(obj => obj.title === this.selectedReport);
  },
  methods: {},
};
  </script>
  <style scoped>
.nav-item-report:hover {
  margin-left: 5px;
  transition: all 0.5s ease;
}
</style>
