var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mt-4",attrs:{"md":"3"}},[_c('b-card',[_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.reports),function(x){return _c('li',{directives:[{name:"permission",rawName:"v-permission",value:(x.permission),expression:"x.permission"}],key:x.title,staticClass:"p-1 nav-item-report cursor-pointer border-bottom",class:{ 'btn-flat-primary': _vm.selectedReport === x.title },on:{"click":function () {
              _vm.selectedReport = x.title;
              _vm.selected = x;
            }}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":x.icon,"size":"20"}}),_c('span',[_vm._v(_vm._s(_vm.$t(x.title)))])],1)}),0)])],1),_c('b-col',{attrs:{"md":"9"}},[(_vm.selectedReport)?_c('b-card-title',{staticClass:"p-0 m-0 text-primary text-darken-5"},[_c('b-row',{staticClass:"p-0"},[_c('b-col',{staticClass:"text-center",attrs:{"md":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.selectedReport)))])])],1)],1):_vm._e(),(_vm.selectedReport)?_c('hr',{staticClass:"border-info border-darken-2"}):_vm._e(),(
        _vm.selectedReport === 'collectionVouchers' ||
        _vm.selectedReport === 'paymentVouchers' ||
        _vm.selectedReport === 'studentsAccountStatement'
      )?_c('collectionVouchers',{attrs:{"type":_vm.selected}}):_vm._e(),(
        _vm.selectedReport === 'incomeTransaction' ||
        _vm.selectedReport === 'expenseTransaction'
      )?_c('incomeTransaction',{attrs:{"type":_vm.selected}}):_vm._e(),(
        _vm.selectedReport === 'financialTransactionAllocation' ||
        _vm.selectedReport === 'financialTransactionAllocation'
      )?_c('financialTransactionAllocation',{attrs:{"type":_vm.selected}}):_vm._e(),(
        _vm.selectedReport === 'reportSalesAllocation'
      )?_c('salesAllocation',{attrs:{"type":_vm.selected}}):_vm._e(),(
        _vm.selectedReport === 'postedAndUnPostedInvoicesToZatcaReport'
      )?_c('postedAndUnpostedInvoicesToZatca',{attrs:{"type":_vm.selected}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }