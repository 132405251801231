<template>
  <b-card>
    <g-form>
      <b-row>
        <!-- from date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.dateFrom"
            label-text="fromDate"
          />
        </b-col>

        <!-- to date  -->
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.dateTo"
            label-text="toDate"
          />
        </b-col>

        <b-col md="4">
          <!-- code  -->
          <g-field
            id="code"
            type="number"
            :value.sync="selectedItem.code"
            name="code"
            label-text="code"
          />
        </b-col>

        <!-- student  -->
        <b-col md="4">
          <student-autocomplete
            ref="autoComlete"
            name="student"
            :value.sync="selectedItem.studentId"
            url="students/getStudentsTaxLookup"
            @change:action="(val) => students.push(val)"
          />
        </b-col>

        <!-- zatca Posting Status Id  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="helper.zatcaPostingStatusId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="postingToZatcaStatus"
              field="select"
              name="zatcaPostingStatusId"
              :options="zatcaPostingStatuses"
              :label="isRight ? 'arabicName' : 'englishName'"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- different print choices -->
      <b-row>
        <b-col
          cols="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="relief-primary"
            @click="print()"
          >
            <feather-icon
              class="mr-50"
              icon="EyeIcon"
            />
            {{ $t("review") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import reportMixin from '@/mixin/reportMixin';
import { reportParameters, zatcaPostingStatuses } from '@/libs/acl/Lookups'
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    StudentAutocomplete,
  },
  mixins: [
    reportMixin,
  ],
  data() {
    return {
      students: [],
      zatcaPostingStatuses: zatcaPostingStatuses,

      selectedItem: {
        dateFrom: null,
        dateTo: null,
        code: null,
        studentId: null,
        postingToZatcaStatus: null
      },

      helper: {
        zatcaPostingStatusId: null
      }
    };
  },
  mounted() {
    this.selectedItem.dateFrom = this.getDate(this.currentYear.startDate)
    this.selectedItem.dateTo = this.getDate(this.currentYear.endDate)
  },
  methods: {
    setDefaultDateIfNotExists() {
      if (this.selectedItem.dateFrom === null) {
        this.selectedItem.dateFrom = this.getDate(this.currentYear.startDate);
      }
      if (this.selectedItem.dateTo === null) {
        this.selectedItem.dateTo = this.getDate(this.currentYear.endDate);
      }
    },

    filterDataBaseOnId(source, compareTo) {
      return source.find((val) => val.id === compareTo)
    },

    preparePrametersForPrint() {
      const selectedStudent = this.filterDataBaseOnId(this.students, this.selectedItem.studentId);
      const allStudents = this.filterDataBaseOnId(reportParameters, 'allStudents');
      this.selectedItem.studentName = this.selectedItem.studentId
                                    ? this.isRight ? selectedStudent.arabicName : selectedStudent.englishName
                                    : this.isRight ? allStudents.arabicName : allStudents.englishName;

      const selectedStatus = this.filterDataBaseOnId(this.zatcaPostingStatuses, this.helper.zatcaPostingStatusId);
      this.selectedItem.zatcaPostingStatus = this.helper.zatcaPostingStatusId
                                           ? this.isRight ? selectedStatus.arabicName : selectedStatus.englishName
                                           : this.$t('all');

      this.selectedItem.isSentToZatca = this.helper.zatcaPostingStatusId
                                      ? this.helper.zatcaPostingStatusId === 'postedToZatca'
                                      : null;

      this.selectedItem.code = this.selectedItem.code
                             ? this.selectedItem.code
                             : null;
    },

    print() {
      this.setDefaultDateIfNotExists();
      if (!this.checkForFiscalYearPerm(this.selectedItem.dateFrom, this.selectedItem.dateTo)) return;
      this.preparePrametersForPrint();
      this.printReport(this.isRight ? 'PostedAndUnpostedTransactionsReport-ar' : 'PostedAndUnpostedTransactionsReport-en', this.selectedItem);
    },
  },
};
</script>

<style>
</style>
